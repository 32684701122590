<template>
  <section
    aria-labelledby="timeline-title"
    class="lg:col-span-1 lg:col-start-1 hidden lg:block"
  >
    <div class="px-4 py-5 sm:px-6">
      <nuxt-link to="/">
        <h2
          id="timeline-title"
          class="text-lg font-medium text-gray-900 cursor-pointer"
        >
          Dashboard
        </h2>
      </nuxt-link>
      <div class="mt-6 flow-root">
        <nav class="flex flex-1 flex-col" aria-label="Sidebar">
          <ul
            role="list"
            class="-mx-2 space-y-1"
            v-if="currentUser.currentUser?.role.title === 'USER'"
          >
            <li>
              <nuxt-link
                to="/anfragen"
                :class="[
                  router.currentRoute.value.path.startsWith('/anfragen')
                    ? 'router-link-active'
                    : '',
                  'text-gray-700 hover:text-[--primary] hover:bg-gray-50 group flex gap-x-3 rounded-[--md-round] p-2 text-sm leading-6 font-semibold',
                ]"
              >
                <svg
                  class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[--primary]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
                Anfragen

                <span
                  v-show="stats.offers"
                  class="ml-auto w-9 min-w-max whitespace-nowrap rounded-[--full-round] bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                  aria-hidden="true"
                  >{{ stats.offers }}</span
                >
              </nuxt-link>
            </li>
            <li>
              <nuxt-link
                to="/angebote"
                :class="[
                  router.currentRoute.value.path.startsWith('/angebote')
                    ? 'router-link-active'
                    : '',
                  'text-gray-700 hover:text-[--primary] hover:bg-gray-50 group flex gap-x-3 rounded-[--md-round] p-2 text-sm leading-6 font-semibold',
                ]"
              >
                <svg
                  class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[--primary]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                  />
                </svg>
                Angebote
                <span
                  v-show="stats.requests"
                  class="ml-auto w-9 min-w-max whitespace-nowrap rounded-[--full-round] bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                  aria-hidden="true"
                  >{{ stats.requests }}</span
                >
              </nuxt-link>
            </li>
            <li>
              <nuxt-link
                to="/bestellungen"
                :class="[
                  router.currentRoute.value.path.startsWith('/bestellungen')
                    ? 'router-link-active'
                    : '',
                  'text-gray-700 hover:text-[--primary] hover:bg-gray-50 group flex gap-x-3 rounded-[--md-round] p-2 text-sm leading-6 font-semibold',
                ]"
              >
                <svg
                  class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[--primary]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                  />
                </svg>
                Bestellungen
                <span
                  v-show="stats.orders"
                  class="ml-auto w-9 min-w-max whitespace-nowrap rounded-[--full-round] bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                  aria-hidden="true"
                  >{{ stats.orders }}</span
                >
              </nuxt-link>
            </li>
          </ul>

          <SideNavAdmin
            currentUser="currentUser"
            v-if="currentUser.currentUser?.role.title === 'ADMIN'"
          />

          <ul
            role="list"
            class="-mx-2 space-y-1"
            v-if="currentUser.currentUser?.role.title === 'CLIENT'"
          >
            <li>
              <nuxt-link
                to="/token"
                :class="[
                  router.currentRoute.value.path.startsWith('/token')
                    ? 'router-link-active'
                    : '',
                  'text-gray-700 hover:text-[--primary] hover:bg-gray-50 group flex gap-x-3 rounded-[--md-round] p-2 text-sm leading-6 font-semibold',
                ]"
              >
                <svg
                  class="h-6 w-6 shrink-0 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
                Einladungen
              </nuxt-link>
            </li>
            <li>
              <nuxt-link
                to="/bookings"
                :class="[
                  router.currentRoute.value.path.startsWith('/bookings')
                    ? 'router-link-active'
                    : '',
                  'text-gray-700 hover:text-[--primary] hover:bg-gray-50 group flex gap-x-3 rounded-[--md-round] p-2 text-sm leading-6 font-semibold',
                ]"
              >
                <svg
                  class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[--primary]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                  />
                </svg>
                Nutzung
              </nuxt-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="px-4 py-5 sm:px-6">
      <div class="mt-6 flow-root shadow rounded-[--xl-round] bg-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="0"
          stroke="#fff"
          class="mx-auto h-12 w-12 text-gray-400 mt-8 fill-[--primary]"
        >
          <path
            d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z"
          />
          <path
            d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z"
          />
        </svg>

        <h3 class="text-center text-base text-gray-900 mb-4 mt-6">
          Nicht das richtige<br />
          Event dabei?
        </h3>
        <p class="text-center text-sm text-gray-900">
          Schicken Sie uns einfach<br />
          eine individuelle Anfrage<br />
          für Ihr Wunsch-Event!
        </p>

        <nuxt-link
          to="/individuell"
          class="block w-3/5 mt-6 mb-6 mx-auto text-center rounded-[--md-round] border-0 py-1.5 text-gray-900 shadow-sm ring-1 bg-[--primary] ring-inset ring-gray-300 placeholder:text-gray-400 text-white focus:ring-2 focus:ring-inset focus:ring-[--primary] sm:text-sm sm:leading-6 disabled:opacity-50"
          >Anfrage</nuxt-link
        >
      </div>
    </div>
  </section>
</template>

<script setup>
import { useAuthStore } from "~/stores/AuthStore";
import { useStatsStore } from "~/stores/StatsStore";
import SideNavAdmin from "~/components/layout/SideNavAdmin.vue";

const router = useRouter();
const currentUser = useAuthStore();
const stats = useStatsStore();
</script>

<style scoped>
.router-link-active {
  color: var(--primary);
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / 1);
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.router-link-active svg {
  color: var(--primary);
}
</style>
