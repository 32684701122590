<template>
  <ul role="list" class="-mx-2 space-y-1">
    <li>
      <nuxt-link
        to="/booker/anfragen"
        :class="[
          router.currentRoute.value.path.startsWith('/booker/anfragen')
            ? 'router-link-active'
            : '',
          'text-gray-700 hover:text-[--primary] hover:bg-gray-50 group flex gap-x-3 rounded-[--md-round] p-2 text-sm leading-6 font-semibold',
        ]"
      >
        <svg
          class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[--primary]"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
          />
        </svg>
        Anfragen

        <span
          v-if="data?.UneditedRequestCount"
          class="ml-auto w-9 min-w-max whitespace-nowrap rounded-[--full-round] bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
          aria-hidden="true"
          >{{ data?.UneditedRequestCount }}</span
        >
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        to="/booker/bestellungen"
        :class="[
          router.currentRoute.value.path.startsWith('/booker/bestellungen')
            ? 'router-link-active'
            : '',
          'text-gray-700 hover:text-[--primary] hover:bg-gray-50 group flex gap-x-3 rounded-[--md-round] p-2 text-sm leading-6 font-semibold',
        ]"
      >
        <svg
          class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[--primary]"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
          />
        </svg>
        Bestellungen

        <span
          v-if="data?.CurrentOrderCount"
          class="ml-auto w-9 min-w-max whitespace-nowrap rounded-[--full-round] bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
          aria-hidden="true"
          >{{ data?.CurrentOrderCount }}</span
        >
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        to="/booker/kunden"
        :class="[
          router.currentRoute.value.path.startsWith('/booker/kunden')
            ? 'router-link-active'
            : '',
          'text-gray-700 hover:text-[--primary] hover:bg-gray-50 group flex gap-x-3 rounded-[--md-round] p-2 text-sm leading-6 font-semibold',
        ]"
      >
        <svg
          class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[--primary]"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
          />
        </svg>
        Auftraggeber
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        to="/booker/token"
        :class="[
          router.currentRoute.value.path.startsWith('/booker/token')
            ? 'router-link-active'
            : '',
          'text-gray-700 hover:text-[--primary] hover:bg-gray-50 group flex gap-x-3 rounded-[--md-round] p-2 text-sm leading-6 font-semibold',
        ]"
      >
        <svg
          class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[--primary]"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
          />
        </svg>
        Token
      </nuxt-link>
    </li>

    <li>
      <nuxt-link
        to="/booker/gutscheine"
        :class="[
          router.currentRoute.value.path.startsWith('/booker/gutscheine')
            ? 'router-link-active'
            : '',
          'text-gray-700 hover:text-[--primary] hover:bg-gray-50 group flex gap-x-3 rounded-[--md-round] p-2 text-sm leading-6 font-semibold',
        ]"
      >
        <svg
          class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[--primary]"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
          />
        </svg>
        Gutscheine
      </nuxt-link>
    </li>
    <li>
      <nuxt-link
        to="/booker/settings"
        :class="[
          router.currentRoute.value.path.startsWith('/booker/settings')
            ? 'router-link-active'
            : '',
          'text-gray-700 hover:text-[--primary] hover:bg-gray-50 group flex gap-x-3 rounded-[--md-round] p-2 text-sm leading-6 font-semibold',
        ]"
      >
        <svg
          class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[--primary]"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
          />
        </svg>
        Einstellungen
      </nuxt-link>
    </li>
  </ul>
</template>

<script setup>
import { GET_ADMIN_STATS } from "~/graphql/queries";
import { GET_OFFERS_ADMIN } from "~/graphql/clients";
import { useQuery } from "@vue/apollo-composable";

const props = defineProps(["currentUser"]);

const router = useRouter();
const { result: data, onResult } = useQuery(GET_ADMIN_STATS, null, {
  pollInterval: 10000,
});
</script>

<style scoped>
.router-link-active {
  color: var(--primary);
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / 1);
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.router-link-active svg {
  color: var(--primary);
}
</style>
