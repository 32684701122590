<template>
  <div class="flex flex-col h-screen">
    <Navigation :logo="currentUser.logo" />

    <div class="flex-grow" style="background-color: #f2f2f2">
      <div class="py-10">
        <div
          class="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-4"
        >
          <SideNav />
          <NuxtPage />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import Navigation from "~/components/layout/Navigation";
import { useAuthStore } from "~/stores/AuthStore";
import { useHighlightStore } from "~/stores/HighlightStore";
import { useStatsStore } from "~/stores/StatsStore";
import SideNav from "~/components/layout/SideNav.vue";
import Footer from "~/components/layout/Footer.vue";
const currentUser = useAuthStore();
const highlights = useHighlightStore();
const stats = useStatsStore();

onMounted(() => {
  currentUser.getUser();
  highlights.getHighlights();
  stats.getStats();
});
</script>

<style>
:root {
  --primary: #fff;
  --secondary: #ff3800;
  --md-round: 0.375rem;
  --lg-round: 0.5rem;
  --xl-round: 0.5rem;
  --full-round: 9999px;
}
</style>
